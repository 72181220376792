'use client';

import type { AxiosError, AxiosResponse } from 'axios';
import type { SWRConfiguration, SWRResponse } from 'swr';
import useSWR from 'swr';
import type { GetRequest } from '~libs/axios-instance';
import { axiosInstance } from '~libs/axios-instance';

export interface Return<Data, Error>
  extends Pick<
    SWRResponse<AxiosResponse<Data>, AxiosError<Error>>,
    'isValidating' | 'error' | 'mutate' | 'isLoading'
  > {
  data: Data | undefined;
  response: AxiosResponse<Data> | undefined;
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<
    SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>,
    'initialData'
  > {
  initialData?: Data;
}

export default function useRequest<Data = unknown, Error = unknown>(
  request: GetRequest | undefined,
  { initialData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> {
  const {
    data: response,
    error,
    isValidating,
    isLoading,
    mutate,
  } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    request && JSON.stringify(request),
    () => {
      return axiosInstance.request<Data>(request as never) as never;
    },
    {
      fallbackData: {
        config: request,
        data: initialData,
        headers: {},
        status: 200,
        statusText: 'InitialData',
      } as AxiosResponse<Data>,
      ...config,
    },
  );
  return {
    data: response?.data,
    response,
    error,
    isLoading,
    isValidating,
    mutate,
  };
}

export const fetcher = axiosInstance.request;
