import { cn } from 'class-merge';
import { objectsToString } from './objects-to-string';

const getDialogStyles = (dialog): Record<string, any> => {
  const { defaultProps, size, className, styles } = dialog;
  const { base, sizes } = styles;

  const backdropClasses = cn(objectsToString(base.backdrop));
  const dialogClasses = cn(
    objectsToString(base.container),
    objectsToString(sizes[(size ?? defaultProps?.size) || 'md']),
  );
  const dialogBodyClasses = cn(
    objectsToString(base.body),
    className ?? defaultProps?.className,
  );

  return {
    backdropClasses,
    dialogClasses,
    dialogBodyClasses,
    appliedAnimation: base.animation,
    backdropAnimation: base.backdropAnimation,
  };
};

export default getDialogStyles;
