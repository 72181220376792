/* eslint-disable @typescript-eslint/no-unsafe-member-access -- needed */
/* eslint-disable @typescript-eslint/no-unsafe-assignment -- needed */
import { AxiosError } from 'axios';
import type { Span } from '@appsignal/javascript/dist/cjs/span';
import { appsignal } from './appsignal';

export const eventErrorSpan = (error: Error, message?: string): Span =>
  appsignal.createSpan((span) => {
    span.setTags({
      type: 'ErrorConstructor',
      message: message || error.message,
    });
    span.setError(error);
  });

export const httpErrorSpan = (
  error: AxiosError | Error,
  message?: string,
): Span =>
  appsignal.createSpan((span) => {
    if (error instanceof Error) {
      span.setTags({
        type: 'ErrorConstructor',
        message: message || error.message,
      });
    }
    if (error instanceof AxiosError) {
      let responseBody: string | undefined = '-';
      try {
        responseBody = JSON.stringify(error.config?.data);
      } catch (e) {
        // ignore
      }
      let requestQuery: string | undefined = '-';
      try {
        requestQuery = JSON.stringify(error.config?.params);
      } catch (e) {
        // ignore
      }
      let requestHeader: string | undefined = '-';
      try {
        requestHeader = JSON.stringify(error.config?.headers);
      } catch (e) {
        // ignore
      }
      span.setTags({
        type: 'AxiosError',
        url: error.config?.url || 'unknown',
        method: error.config?.method || 'unknown',
        response_status_code: `${error.response?.status || 'unknown'}`,
        request_body: responseBody,
        message: message || error.response?.data?.message || error.message,
        request_query: requestQuery,
        request_header: requestHeader,
      });
    }
    span.setError(error);
  });
