'use client';

import type { FC, ReactNode } from 'react';
import React, { createContext, useContext } from 'react';
import type { AgentInfoResponseInterface } from '~libs/get-agent-info';

const AgentProviderContext = createContext<{
  agentInfo: AgentInfoResponseInterface | undefined;
}>({
  agentInfo: undefined,
});

interface AgentProviderProps {
  children: ReactNode;
  agentInfo: AgentInfoResponseInterface;
}

export const AgentProvider: FC<AgentProviderProps> = ({
  children,
  agentInfo,
}) => {
  return (
    <AgentProviderContext.Provider value={{ agentInfo }}>
      {children}
    </AgentProviderContext.Provider>
  );
};

export const useAgent = (): {
  agentInfo: AgentInfoResponseInterface | undefined;
} => {
  return useContext(AgentProviderContext);
};
